import "lazysizes";
import Scrollbar from "smooth-scrollbar";
import BezierEasing from "bezier-easing";
import '../js/components/_toggleClassname';

// SCROLL
// -- Init
var scaleratio = $(window).outerHeight() / 1000;
var aniComplete = true;
$("#navBurger").each(function(){
  $("#navBurger").toggleClassname({
    toggle: [
      {
        el: $("#floattop"),
        className: 'active'
      },
      {
        el: $("#floatnav"),
        className: 'active'
      },
      {
        el: $("#navBurger"),
        className: 'is-active'
      }
    ]
  });
});

if ($(".scrollContent").length > 0) {
  let frameSwipe = {
    total: 5,
    frames: [
      ".section--1",
      ".section--2",
      ".section--3",
      ".section--4",
      ".section--5",
    ],
    navigation: ".nav-top li",
    index: 0,
    isEndAnimate: true,
    animateDelay: 400,
    animateEndDelay: 400,
  };

  $(".scrollLink").on("click", function (e) {
    e.preventDefault();
    if (frameSwipe.isEndAnimate) {
      frameSwipe.isEndAnimate = false;

      $("html").animate(
        {
          scrollTop: $($(this).data("scroll-link")).offset().top,
        },
        600,
        function () {
          frameSwipe.isEndAnimate = true;
        }
      );
    }
  });

  // if (!viewportIsMobile($("body").outerWidth())) {

  //   // scrollbars['root'] = Scrollbar.init($(".scrollContent")[0], {
  //   //   alwaysShowTracks: true,
  //   //   damping: vdamping
  //   // });

  // }
  // else {
  //   $(".scrollLink").each(function () {
  //     $(this).on("click", function (e) {
  //       e.preventDefault();
  //       $(".scrollLink").removeClass("active");
  //       $(this).addClass("active");
  //       if (aniComplete == true) {
  //         aniComplete = false;
  //         $("body,html").animate({
  //           scrollTop: $($(this).data("scroll-link")).offset().top
  //         }, 600, function () {
  //           aniComplete = true;
  //         });

  //       }
  //     })
  //   });
  // }
}

import Swiper from "Swiper";
import "swiper/css/swiper.min.css";


let  scrollBarDownload=   Scrollbar.init($(".downloadScrollContent")[0], {
  alwaysShowTracks: true,
});
console.log('scrollBarDownload',scrollBarDownload)
var NewsSwiper = new Swiper(".NewsSwiper", {
  slidesPerView: 2,
  spaceBetween: 40,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  centeredSlides: false,
  loop: false,
  navigation: {
    nextEl: ".section--4 .swiper-button-next",
    prevEl: ".section--4 .swiper-button-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 4
    }
  }

});

if ($("#blockHomeFeatureSwiper").length > 0) {
  var videoSwiper = new Swiper("#blockHomeFeatureSwiper", {
      pagination: {
        el: ".swiper-pagination.swiper-pagination--featureSwiper",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next.swiper-button-next--featureSwiper",
        prevEl: ".swiper-button-prev.swiper-button-prev--featureSwiper",
      },
      effect: "coverflow",
      loop: true,
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 540,
        depth: 1000,
        slideShadows: false,
      },
    }
  )
}
